import { BASE_URL } from "../lib/common";
import axios from 'axios';

export const getBackgrounds = async () => {
    try {
        const response = await axios.get(`${BASE_URL}listHDRFiles`);
        if (!response.data.success) {
            throw new Error('Network response was not ok');
        }

        if (response.data.success) {
            return response.data.files;
        }
        else {
            return [];
        }
    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;
    }
}


// export const getStyleNumbers = (products) => {
//     const styleNumbers = [];
//     for(let i = 0; i < products.length; i++) {
//         styleNumbers.push(products[i]['productName']);
//     }
// }